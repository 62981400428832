angular.module("app.config", [])
.constant("envConfig", {"envName":"staging"})
.constant("version", "1.0")
.constant("appPath", "./app")
.constant("assetsPath", "./app/assets")
.constant("commonPath", "./app/common")
.constant("buildPath", "./dist")
.constant("depPaths", ["./app/bower_components/angular/angular.js","./app/bower_components/angular-ui-router/release/angular-ui-router.min.js","./app/bower_components/angular-sanitize/angular-sanitize.min.js","./app/bower_components/angular-animate/angular-animate.min.js","./app/bower_components/angular-messages/angular-messages.min.js","./app/bower_components/angular-flash-alert/dist/angular-flash.min.js","./app/bower_components/angular-ui-select/dist/select.min.js","./app/bower_components/angular-bootstrap/ui-bootstrap-tpls.min.js","./app/bower_components/waypoints/lib/noframework.waypoints.min.js","./app/bower_components/angulartics/dist/angulartics.min.js","./app/bower_components/angulartics-google-analytics/dist/angulartics-ga.min.js","./app/bower_components/angulartics-hubspot/dist/angulartics-hubspot.min.js","./app/bower_components/jquery/dist/jquery.slim.min.js","./app/bower_components/bodymovin/build/player/lottie.min.js"])
.constant("rootUrls", {"labs":"tools-stage.synthego.com","ice":"icestage.synthego.com","design":"design-stage.synthego.com"});

'use strict';

angular.module('app', [
    'ui.router',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ngMessages',
    'ngFlash',
    'ui.select',
    'angulartics',
    'angulartics.google.analytics',
    'angulartics.hubspot',
    'app.config'
  ])
  .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$urlMatcherFactoryProvider', 'FlashProvider', function($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider, FlashProvider) {
    // An array of state definitions
    var states = [
      {
        name: 'error',
        templateUrl: './views/error.f37096ae.html',
        controller: 'error',
        params: {
          message: 'Something went wrong.',
          backTo: 'landing'
        },
        data: {
          title: 'Error',
          pageClass: 'error'
        }
      },
      {
        name: 'landing',
        url: '/',
        templateUrl: './views/landing.082a8d8f.html',
        data: {
          title: 'Bioinformatics Tools',
          pageClass: 'landing'
        }
      }
    ]

    // Loop over the state definitions and register them
    states.forEach(function(state) {
      $stateProvider.state(state);
    });

    $urlRouterProvider.otherwise('/');
    $locationProvider.hashPrefix('');

    // Match trailing slashes in URLs
    $urlMatcherFactoryProvider.strictMode(false);

    // Enable this once index document is set on AWS
    //$locationProvider.html5Mode(true);

    FlashProvider.setTimeout(0);
  }])

  .run(['$rootScope', '$state', '$stateParams', '$transitions', '$uibModalStack', 'Flash', 'envConfig', 'version', 'rootUrls', function ($rootScope, $state, $stateParams, $transitions, $uibModalStack, Flash, envConfig, version, rootUrls) {
    // Make states available for classes and conditionals
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    // Navbar initial state
    $rootScope.isNavCollapsed = true;
    $rootScope.isCollapsed = false;

    $rootScope.envConfig = envConfig;
    $rootScope.version = version;
    $rootScope.date = new Date();
    $rootScope.rootUrls = rootUrls;

    // Triggers on any transition
    $transitions.onStart({ to: '**' }, function(transition) {
      // Clear modals whenever state starts to change and cancel transition
      var top = $uibModalStack.getTop();
      if (top) {
        $uibModalStack.dismiss(top.key);
        $state.reload();
      }
      // Clear any flash messages
      Flash.clear();
    });
  }]);


app.directive('debugMenu', [function () {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: './views/shared/_debug-menu.html',
    controller: ['$scope', 'userStorage', 'analyze', function($scope, userStorage, analyze) {
      $scope.userStorage = userStorage;
    }]
  };
}]);
// Miscellaneous helper directives

angular.module('app')
  // The animated logo loader
  .directive('logoLoader', [function () {
    return {
      restrict: 'EA',
      template: '<div class="logo-loader" ><div><div></div><div></div><div></div><div></div></div></div>'
    };
  }])
  // Help tooltip elements
  .directive('helpTooltip', [function () {
    return {
      restrict: 'EA',
      scope: {
        text: '@'
      },
      template: '<span class="help-icon" uib-tooltip="{{text}}" tooltip-append-to-body="true" tooltip-placement="auto top"><i class="material-icons md-16">help_outline</i></span></span>'
    };
  }])
  // Back button
  .directive('back', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.history.back();
          });
      }
    };
  }])
  // Reload button
  .directive('reload', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.location.reload();
          });
      }
    };
  }])
  // Waypoints
  .directive('waypoint', [function(){
    return {
      restrict: 'EA',
      link: function(scope, element, attrs) {
        new Waypoint({
          element: element[0],
          handler: function(direction) {

            if(direction == 'down') {
              element.removeClass('scrolled-to scrolled-to-up').addClass('scrolled-to scrolled-to-down');
            } else if(direction == 'up') {
              element.removeClass('scrolled-to-down').addClass('scrolled-to-up');
            }
            
          },
          offset: '75%'
        })
      }
    }
  }]);
angular.module('app')
  .directive('hubspotForm', [function () {
      return {
        restrict: 'EA',
        link: function (scope, element, attrs) {
          hbspt.forms.create({
            css: '',
            portalId: '2418554',
            formId: attrs.formId,
            target: '#'+attrs.id,
            formInstanceId: attrs.id,
            submitButtonClass: 'btn btn-primary btn-lg '+attrs.btnClass,
            errorClass: 'has-error',
            errorMessageClass: 'help-block has-error',
          });
        }
      };
    }])
angular.module('app')
  .directive('landingAnimation', [function () {
      return {
        restrict: 'EA',
        scope: {
          action: '='
        },
        link: function (scope, element, attrs) {

          var animation = bodymovin.loadAnimation({
            container: element[0],
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: attrs.jsonSrc
          })

          scope.$watch('action', function(action) {

            if(action == 'play') {
              animation.goToAndStop(0, true);
              animation.setDirection(1);
              animation.setSpeed(1);
              animation.play();
            }

            if(action == 'stop') {
              animation.setDirection(-1);
              animation.setSpeed(3);
              animation.play();
            }

          });

        }
      };
    }])
app.directive('recentMenu', [function () {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: './views/shared/_recent-menu.html',
    controller: ['$scope', 'userStorage', 'analyze', function($scope, userStorage, analyze) {

      //Get options from localstorage
      $scope.analyzeOptions = userStorage.analyzeOptions;

      //When the recents menu is opened
      $scope.toggled = function(open) {

        if(open) {

          //Loop through the recent runs
          $scope.analyzeOptions.recentRuns.forEach(function(run, index) {

            //If they're still processing, check if they're done yet
            if(run.status == 'processing') {

              analyze.getAnalysis(run.id)
                .then(function(response) {
                  $scope.analyzeOptions.recentRuns[index].status = response.data.status;
                  if(response.data.completed_time != undefined) {
                    $scope.analyzeOptions.recentRuns[index].timestamp = response.data.completed_time;
                  }
                })
                .catch(function(error) {
                  console.log('ERROR', error);
                });
            }
          });
        }
        
      };

    }]
  };
}]);
app.directive('shareMenu', [function () {
  return {
    restrict: 'EA',
    scope: {
      dropdownClass: '@'
    },
    templateUrl: './views/shared/_share-menu.html'
  };
}])
angular.module('app')
  .controller('error', ['$scope', '$stateParams', function ($scope, $stateParams) {

    $scope.errorMessage = $stateParams.message;
    $scope.backTo = $stateParams.backTo;
    
  }]);

angular.module('app')
  .controller('modals', ['$scope', '$uibModal', function ($scope, $uibModal) {
    $scope.openModal = function (templateUrl, size) {

      $uibModal.open({
        templateUrl: templateUrl,
        size: size,
        scope:$scope,
      }).result.then(function(){}, function(res){});
      
    }
  }]);
